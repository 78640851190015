import { GatsbySeo } from 'gatsby-plugin-next-seo'
import SocialComponent from 'src/components/Institucional/SustentabilidadeBrasil/SocialComponent'

function Ambiental() {
  return (
    <>
      <GatsbySeo title="Institucional | Decathlon" noindex nofollow />
      <SocialComponent />
    </>
  )
}

export default Ambiental
